import { Moment } from 'moment';
import ko from 'knockout';
import VueRouter from 'vue-router';

export interface ITimeControl {
  time: KnockoutObservable<Moment | undefined>;
  isRealTime: KnockoutObservable<boolean>;

  travelTo(time: Moment): Promise<void>;
}

export class TimeControl implements ITimeControl {
  private static _instance: TimeControl;

  public static get = (router: VueRouter): ITimeControl => {
    if (!TimeControl._instance) {
      TimeControl._instance = new TimeControl(router);
    }
    return TimeControl._instance;
  };

  private constructor(private _router: VueRouter) {}

  public time = ko.observable<Moment | undefined>(undefined);
  public isRealTime = ko.observable(true);

  public async travelTo(time: Moment) {
    const route = this._router.currentRoute;
    const query = Object.assign({}, route.query);
    if (query.time) query.time = time.format('YYYY-MM-DD-HHmm');
    if (query.dispatchInterval) query.dispatchInterval = time.format('YYYY-MM-DD-HHmm');
    await this._router.push({ query });
  }
}
