




import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import moment from 'moment';
import { TimeControl } from '@/common/timeContext';

@Component
export default class App extends Vue {
  @Watch('$route', { immediate: false, deep: true }) onUrlChange(newVal: Route) {
    const timeQuery = newVal.query.time || newVal.query.dispatchInterval;
    const isRealTimeQuery = newVal.query.isRealTime;

    const time = moment(timeQuery as string, 'YYYY-MM-DD-HHmm').utcOffset(10, true);
    const isRealTime = isRealTimeQuery != 'false';

    const timeControl = TimeControl.get(this.$router);
    timeControl.time(time.isValid() ? time : undefined);
    timeControl.isRealTime(isRealTime);
  }
}
